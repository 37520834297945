.show{
    display: block;
}
.hide{
    display: none;
}
.filter-container .ant-radio-group.ant-radio-group-outline {
    max-height: 250px;
    overflow-y: scroll;
}
.borderDisplay{
    display:flex;
    align-items: center;
    border: 1px solid #D90068;
      padding: 5;
      position: relative;
      font-size: 12;
      flex-wrap:wrap;
}
.t-div {
    display: flex;
    align-items: center;
    @media (max-width:768px) {
        display: flex;
       flex-direction: column;
       gap: 5px;
       align-items: unset;
    }
}
.wrap-div{
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width:768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}