.add-product-modal {
  .ant-row {
    margin-bottom: 6px !important;
  }

  .variant-container {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 8px 2px;
  }

  .ant-badge {
    margin-bottom: 10px;
  }

  .error_link::-webkit-scrollbar {
    display: none;
  }
  .ant-badge:last-child {
    margin-bottom: 0px;
  }

  .boxes {
    padding: 5px 10px;
    background-color: #D90068;
    border-radius: 10px;
    color: #fff;
  }

  .variant {
    border: 1px solid rgb(217, 217, 217);
    // width: 140px;
    // height: 30px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    padding: 10px;

    .btn {
      cursor: pointer;
    }

    .delete-variant-btn {
      position: absolute;
      right: 0px;
      bottom: 3px;
      background: #ff4d4f;
      color: white;
      padding-top: 0px;
      padding-bottom: 4px;
      border-radius: 15px;
      padding-left: 3px;
      padding-right: 3px;
      font-weight: normal;
    }
  }
}

.row-wrapper {
  display: flex;
  justify-content: space-between;

  // .ant-form-item-control:last-child {
  //   text-align: right;
  // }

  & .ant-form-item-children,
  .ant-legacy-form-item-children {
    .ant-input-number,
    .ant-input-number-lg {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }

  @media screen and (max-width: 475px) {
    & {
      flex-direction: column;
    }

    & .ant-form-item-control,
    .ant-legacy-form-item-control {
      .ant-form-item-children,
      .ant-legacy-form-item-children {
        .ant-input-number,
        .ant-input-number-lg {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }
    }
  }
}

.ant-upload-list{
  display: none;
}

.div_mark {
  display: flex;
  align-items: center;
  margin-top: 15;
  @media (max-width:768) {
    
  }
}
